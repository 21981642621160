import { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Paper,
  Grid,
  Typography,
  Link,
  styled,
} from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { SectionWrapper } from "../../../styles/sectionStyles";
import GitHubImg from "../../../assets/github.png";
import TelegramImg from "../../../assets/telegram.svg"
import CryptoPriceBotImg from "../../../assets/CryptoPriceBot.png"

const StyledImg = styled("img")(({ theme }) => ({
  padding: "25px",
  [theme.breakpoints.down("md")]: {
    width: 500,
    padding: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    width: 300,
    padding: "10px",
  },
}));
const StyledScreenshot = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
  },
}));

const StyledLogo = styled("img")(({ theme }) => ({
  height: 42,
  width: 42,
  display: 'block',
  margin: 'auto',
  padding: '2px',
  [theme.breakpoints.down("md")]: {
    height: 30,
    width: 30,
  },
  [theme.breakpoints.down("sm")]: {
    height: 24,
    width: 24,
  },
}));

const StylePubicationIcon = styled(MenuBookIcon)(({ theme }) => ({
  height: 36,
  width: 36,
  display: 'block',
  margin: 'auto',
  [theme.breakpoints.down("md")]: {
    height: 24,
    width: 24,
  },
  [theme.breakpoints.down("sm")]: {
    height: 20,
    width: 20,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.75rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const ProjectsContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SectionWrapper>
      <Card sx={{ marginBottom: "15px" }}>
        <CardContent>
          <Grid container sx={{ display: 'flex', borderRadius: '50%' }}>
            <Grid item xs={1}>
              <StyledLogo
                alt="Github Logo"
                src={GitHubImg}
              />
            </Grid>
            <Grid item xs={10}>
              <StyledTitle>GitHub Contributions</StyledTitle>
            </Grid>

            <Grid xs={12}>
              <Paper variant='outlined'>
                <StyledImg
                  alt="Leonard's Github Chart"
                  src="https://ghchart.rshah.org/409ba5/shih91"
                />
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginBottom: "15px" }}>
        <CardContent>
          <Grid container sx={{ display: 'flex', borderRadius: '50%' }}>
            <Grid item xs={1}>
              <StylePubicationIcon />
            </Grid>
            <Grid item xs={10}>
              <StyledTitle>Publications</StyledTitle>
            </Grid>

            <Grid xs={12}>
              <Paper variant='outlined'>
                <ul>
                  <li>
                    <Link href="https://ieeexplore.ieee.org/document/9140303/" variant="body2" underline="hover">
                      A 3-Mbps, 802.11g-Based EMG Recording System With Fully Implantable 5-Electrode EMGxbrk Acquisition Device
                    </Link>
                  </li>
                  <li>
                    <Link href="https://ieeexplore.ieee.org/document/8708912/" variant="body2" underline="hover">
                      A Wireless Multi-Channel Peripheral Nerve Signal Acquisition System-on-Chip
                    </Link>
                  </li>
                  <li>
                    <Link href="https://ieeexplore.ieee.org/document/8584753/" variant="body2" underline="hover">
                      A Fully Wireless Implantable Multi-Channel Muscle Stimulator with Closed-Loop Feedback Control
                    </Link>
                  </li>
                  <li>
                    <Link href="https://ieeexplore.ieee.org/document/8325095/" variant="body2" underline="hover">
                      Programmable biphasic multi-channel constant current muscle stimulator with wireless power and data transfer
                    </Link>
                  </li>
                  <li>
                    <Link href="https://ieeexplore.ieee.org/document/8325079/" variant="body2" underline="hover">
                      A chronic implantable EMG recording system with wireless power and data transfer
                    </Link>
                  </li>
                </ul>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginBottom: "15px" }}>
        <CardContent>
          <Grid container sx={{ display: 'flex', borderRadius: '50%' }}>
            <Grid item xs={1}>
              <StyledLogo
                alt="Telegram Logo"
                src={TelegramImg}
              />
            </Grid>
            <Grid item xs={10}>
              <StyledTitle>Telegram Crypto Price Bot</StyledTitle>
            </Grid>

            <Grid xs={12}>
              <Paper variant='outlined'>
                <Paper elevation={0} sx={{ m: 2 }}>
                  <Chip size='small' sx={{ m:1, backgroundColor: '#6489a7', color: '#ffffff'}} label="Hobby" />
                  <Chip size='small' sx={{ m:1, backgroundColor: '#6489a7', color: '#ffffff'}} label="Telegram" />
                  <Chip size='small' sx={{ m:1, backgroundColor: '#6489a7', color: '#ffffff'}} label="Node.js" />
                  <Typography variant="body2" sx={{ padding: "10px" }}>
                    A Telegram bot written in Node.js that utilizes the CoinGecko API to fetch and display the latest prices, price changes, market cap, volume, and more for various cryptocurrencies.
                  </Typography>
                  <Typography variant="body2" sx={{ padding: "10px" }}>
                    The bot is designed to provide easy access to cryptocurrency data directly within your Telegram chat.
                  </Typography>
                </Paper>
                <StyledScreenshot
                  alt="Telegram Crypto Price Bot"
                  src={CryptoPriceBotImg}
                  sx={{
                    display: 'block',
                    margin: 'auto',
                    height: 500
                  }}
                />
                <Paper elevation={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Box component="span" sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button href='https://github.com/shih91/CryptoPriceBot'>Repository</Button>
                  </Box>
                  <Box component="span" sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button href='https://t.me/LeoCryptoPriceBot'>Try me!</Button>
                  </Box>
                </Paper>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </SectionWrapper>
  );
};
