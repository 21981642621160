// import Google from '../assets/google.svg';
import Sinapse from '../assets/sinapse.svg';
import Gets from '../assets/gets.svg';
import Besi from '../assets/besi.png';
import ProjectImg from '../assets/project.jpg';
interface Map {
	[key: string]: string | undefined;
}

export const logo: Map = {
	sinapse: Sinapse,
	gets: Gets,
	besi: Besi,
};

export enum Constants {
	USERNAME = 'Leonard',
}

// "project name 1": ProjectImg
// "project name 1" - project name mentioned in Projects.json (lower cased)
// ProjectImg - imported name
export const projectImage: Map = {
	'project name 1': ProjectImg,
	'project name 2': ProjectImg,
};
