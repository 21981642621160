import {Box, styled} from '@mui/material';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from '../../../store/types';
import {sectionImages} from '../utils';
import {setCurrentSection} from './slice';
import Arrow from '../../../assets/arrow.png';
import {useNavigationSize} from '../../../hooks/useNavigationSize';
import {StyleConstants} from '../../../styles/StyleConstants';
import { Tooltip } from 'react-tooltip'

interface NavigationWrapperProps {
	navWidth: string;
  }

const NavigationWrapper = styled(Box)<NavigationWrapperProps>(({theme, navWidth}) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	backgroundColor: StyleConstants.NAVIGATION_BG,
	padding: '5px 20px',
	marginBottom: 25,
	borderRadius: '30px',
	position: 'sticky',
	width: navWidth,
	'& .nav-section': {
		margin: '10px',
		display: 'flex',
		flexDirection: 'column',
	},
	'& .nav-item': {
		height: 35,
		width: 35,
		padding: '5px',
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: StyleConstants.SECTION_BORDER,
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& img': {
			height: 30,
			width: 30,
		},
		'&:hover': {
			borderColor: StyleConstants.WHITE_COLOR,
		},
	},
	'& .arrow': {
		margin: '10px',
		height: 35,
		width: 35,
		padding: '5px',
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transform: 'rotate(271deg)',
		'& img': {
			height: 26,
			width: 26,
		},
	},
	'& .before': {
		transform: 'rotate(90deg)',
	},
	'& .disabled': {
		opacity: '0.1',
		pointerEvents: 'none',
	},
	'& .active .nav-item': {
		backgroundColor: StyleConstants.ACTIVE_SECTION,
	},
	[theme.breakpoints.down('md')]: {
		justifyContent: 'center',
	},
}));
export const NavigationList = () => {
	const dispatch = useDispatch();
	const sections = useSelector((state: IRootState) => state.sections?.sections) || [];
	const selectedSection = useSelector((state: IRootState) => state.sections?.currentSection) || '';
	const filteredSections = sections.filter((sec) => !sec?.hide);
	const [start, setStart] = useState(0);
	const limit = useNavigationSize();
	const [navWidth, setNavWidth] = useState('450px');

	useEffect(() => {
		if (start + filteredSections.length  > limit) {
			setStart(Math.min(filteredSections.length - limit, start));
		}
		else{
			setStart(0);
		}
		limit > 4 ? setNavWidth('450px') : 
		limit > 3 ? setNavWidth('400px') : 
		limit > 2 ? setNavWidth('300px') : 
		setNavWidth('250px');
	}, [limit]);
	return (
		<NavigationWrapper navWidth={navWidth}>
			{limit < filteredSections.length && (
				<Box className={`arrow before ${start === 0 && 'disabled'}`} onClick={() => setStart((val) => val - 1)}>
					<img src={Arrow} />
				</Box>
			)}
			{filteredSections.slice(start, start + limit).map((sec) => (
				<Box key={sec.key} className={`nav-section ${selectedSection === sec.key && 'active'}`}>
					<Box className="nav-item" onClick={() => dispatch(setCurrentSection(sec.key))}>
						<Tooltip id={sectionImages[sec.key]?.key} />
						<img data-tooltip-id={sectionImages[sec.key]?.key} 
								data-tooltip-content={sectionImages[sec.key]?.toolTip} 
								src={sectionImages[sec.key]?.key} 
								alt={sec.key} />
					</Box>
				</Box>
			))}
			{limit < filteredSections.length && (
				<Box className={`arrow after ${start === filteredSections.length - limit && 'disabled'}`} onClick={() => setStart((val) => val + 1)}>
					<img src={Arrow} />
				</Box>
			)}
		</NavigationWrapper>
	);
};