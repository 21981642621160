import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  Stack,
  Box,
  Grid,
  styled,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ImageBox = styled(Box)(({ theme }) => ({
  width: 350,
  minHeight: 150,
  border: "1px solid black",
  "& img": {
    width: 350,
  },
  [theme.breakpoints.down("sm")]: {
    width: 300,
    "& img": {
      width: 300,
    },
  },
}));

interface submodule {
  index?: number;
  name?: string;
  credentialId?: string;
  credentialUrl?: string;
  url?: string;
}
interface Prop {
  submodules: Array<submodule> | undefined;
}

export const Submodule = ({ submodules }: Prop) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      {submodules?.map((submodule, index) => (
        <Accordion
          key={index}
          expanded={expanded === "panel" + index}
          onChange={handleChange("panel" + index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{submodule.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={6}>
                <ImageBox>
                  <a
                    href={submodule.credentialUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={submodule.url} alt="certificate" />
                  </a>
                </ImageBox>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
