import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  Box,
  Grid,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Submodule } from "./Submodule";

const ImageBox = styled(Box)(({ theme }) => ({
  width: 350,
  minHeight: 150,
  border: "1px solid black",
  "& img": {
    width: 350,
  },
  [theme.breakpoints.down("sm")]: {
    width: 340,
    "& img": {
      width: 340,
    },
  },
}));

interface submodule {
  index?: number;
  name?: string;
  credentialId?: string;
  credentialUrl?: string;
  url?: string;
}

interface certficate {
  name?: string;
  org?: string;
  issued?: string;
  expiry?: string;
  credentialId?: string;
  credentialUrl?: string;
  url?: string;
  submodules?: Array<submodule> | []; // array of submodule objects
}

interface Prop {
  certficates: Array<certficate>;
}

export const CertificationContatiners = ({ certficates }: Prop) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {certficates.map((certficate, index) => (
        <Accordion
          key={index}
          expanded={expanded === "panel" + index}
          onChange={handleChange("panel" + index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{certficate.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} md={6}>
                <ImageBox>
                  <a
                    href={certficate.credentialUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={certficate.url} alt="certificate" />
                  </a>
                </ImageBox>
                <Typography>Attained From: {certficate.org}</Typography>
                <Typography>Issued: {certficate.issued}</Typography>
                {certficate.expiry && (
                  <Typography>Expiry: {certficate.expiry}</Typography>
                )}
                {certficate.credentialId && (
                  <Typography>Id: {certficate.credentialId}</Typography>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {
                    <Submodule submodules={certficate.submodules}/>
                }
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
