import { Grid, Card, CardContent, Typography } from "@mui/material";
import NusLogo from "../../../assets/nus.png";
interface Education {
  name?: string;
  course?: string;
  location?: string;
  span?: string;
  specialization?: string;
  score?: string;
}
interface Prop {
  education: Education;
  ind: number;
}
export const Education = ({ education, ind }: Prop) => {
  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item>
              <a href="https://www.nus.edu.sg/" target="_blank" rel="noreferrer">
              <img src={NusLogo} alt="NUS Logo" height="69px" width="150px"/>
              </a>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {education.name} ∙ ({education.span})
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {education.course}
              </Typography>
              <Typography variant="body2">
                🇸🇬 {education.location}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
