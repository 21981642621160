import React, { useState, useEffect } from 'react';
import {styled, Box} from '@mui/material';
import {StyleConstants} from '../../styles/StyleConstants';

const IntroductionContainer = styled(Box)(({theme}) => ({
	position: 'fixed',
	height: 35,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: StyleConstants.HEADER,
	top: 0,
	fontSize: '0.75em',
	zIndex: 1,
	fontWeight: 700
}));

interface LocationData {
  city: string;
  country: string;
}

export const Header = () => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    // Fetch the current date, time, and location
    const getCurrentDateTime = () => {
      const currentDate = new Date();
      const currentDateString = currentDate.toDateString();
      const currentTimeString = currentDate.toLocaleTimeString();
      setDate(currentDateString);
      setTime(currentTimeString);
    };
    getCurrentDateTime();

	    // Update date and time every second
		const intervalId = setInterval(getCurrentDateTime, 1000);

		// Cleanup the interval on component unmount
		return () => {
		  clearInterval(intervalId);
		};
  }, []);

  return (
	<IntroductionContainer>
      <p>{date} {time} ∙ 🇸🇬 Singapore</p>
	</IntroductionContainer>
  );
};