import { useState, useEffect } from "react";
import { Paper, Grid, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { SectionWrapper, TitleWrapper } from "../../../styles/sectionStyles";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import data from "../../../data/Skills.json";

export const SkillsContainer = () => {

  const [state, setState] = useState<string | null>('hard');

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newState: string | null,
  ) => {
    setState(newState);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SectionWrapper>
      <TitleWrapper>Skills</TitleWrapper>
      <Paper sx={{marginTop: "5px", padding: "20px"}}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
        <ToggleButtonGroup
          value={state}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="hard" aria-label="left aligned">
            Hard Skills
          </ToggleButton>
          <ToggleButton value="soft" aria-label="centered">
            Soft Skills
          </ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
            <>
              { state === 'hard' 
              && <ResponsiveContainer width="100%" height={300}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data.hardSkills}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <Radar name="Hard Skills" dataKey="competency" stroke="#8884d8" fill="#8884d8" label='false' fillOpacity={0.6} />
                  </RadarChart>
                </ResponsiveContainer>
              }
              { state === 'soft' 
              && <ResponsiveContainer width="100%" height={300}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data.softSkills}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <Radar name="Soft Skills" dataKey="competency" stroke="#8884d8" fill="#8884d8" label='false' fillOpacity={0.6} />
                  </RadarChart>
                </ResponsiveContainer>
              }
            </>
          </Grid>
        </Grid>
        </Paper>
    </SectionWrapper>
  );
};
