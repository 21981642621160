import { styled, Box } from "@mui/material";
import MyImage from "../../../assets/pic.png";
import Resume from "../../../assets/resume.pdf";
import {
  CustomButton,
  SectionWrapper,
  TitleWrapper,
  SubtitleWrapper,
} from "../../../styles/sectionStyles";
import aboutMeInfo from "../../../data/AboutMe.json";
import { useEffect } from "react";
const AboutMeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  "& img": {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const DetailsWrapper = styled(Box)(({ theme }) => ({
  fontSize: 16,
  "& .actions": {
    margin: "12px 0",
  },
  [theme.breakpoints.down("md")]: {
    margin: "10px 0 0 0",
  },
}));
export const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SectionWrapper>
      <TitleWrapper>Leonard Liu S.C.</TitleWrapper>
      <SubtitleWrapper>Software Engineer. Currently making Java beans React to JavaScript at <a href="https://globaletrade.services/">GeTS</a>!</SubtitleWrapper>
      <AboutMeContainer>
        <DetailsWrapper>
          <div>{aboutMeInfo.map((aboutMe)=>(
            <p>{aboutMe.description}</p>
          ))}
          </div>
          <div className="actions">
            <CustomButton>
              <a href={Resume} rel="noreferrer" target="_blank">
                View Resume
              </a>
            </CustomButton>
          </div>
        </DetailsWrapper>
      </AboutMeContainer>
    </SectionWrapper>
  );
};
