import { Box, styled, Card, CardContent, Typography, CardActions, Collapse, CardHeader, Avatar, Chip, Link } from "@mui/material";
import { StyleConstants } from "../../../styles/StyleConstants";
import { useState, useEffect } from "react";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { logo } from "../../../data/constants";

interface IExperience {
  role: string;
  span?: string;
  company?: string;
  companyLink?: string;
  companyLogo?: string;
  skills?: any;
  descriptions: any;
}
interface Prop {
  experience: IExperience;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const Wrapper = styled(Box)(({ theme }) => ({
  alignItems: "center",
  marginBottom: StyleConstants.SPACE_BETWEEN_SECTIONS,
  "& .span": {
    padding: "0 0 6px",
  },
  "& .description-wrapper": {
    paddingBottom: 10,
  },
  "& .description": {
    padding: "0 0 6px 12px",
    display: "flex",
    alignItems: "center",
    "& .bullet": {
      height: 10,
      minWidth: 10,
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: 10,
    },
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Experience = ({ experience }: Prop) => {
  const {
    role = "",
    company = "",
    companyLink = "",
    companyLogo = "",
    span,
    skills = [],
    descriptions = [],
  } = experience;

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Card>
      <CardHeader
        avatar={
          <Avatar variant='square' aria-label="recipe" src={logo[companyLogo.toLowerCase()]} sx={{width: '65px', height: '80px'}}>
          </Avatar>
        }
        title={role}
        subheader={
            <Link href={companyLink} target="_blank" rel="noreferrer noopener" underline="none" sx={{}}>
              <Typography 
                variant="subtitle2" 
                component="span" 
                color="textSecondary"
                sx={{
                  '&:hover': {
                    color: 'blue',
                  },
                }}>
                {company} - [{span}]
              </Typography>
            </Link>
        }
      />
      <CardContent>
        {skills.map((skill: any) => {
          return (
            <Chip
              avatar={<Avatar variant='square' alt="Natacha" src={skill.logo}sx={{width: '20px', height: '20px'}}/>}
              label={skill.name}
              sx={{marginRight: 1, marginBottom: 1, width: '125px'}}
            />
          )})
          }
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
            <ul>
            {descriptions.map((description: any) => {
              return (
                <Typography variant='caption'>
                  <li>
                    {description}
                  </li>
                </Typography>
              );
            })
            }
            </ul>
        </CardContent>
      </Collapse>
    </Card>
    </Wrapper>
  );
};
export default Experience;
