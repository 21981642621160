export enum StyleConstants {
  WHITE_COLOR = "#ffffff",
  HEADER = "#6489a7",
  FOOTER = "rgba(100,137,167,0.25)",
  ACTIVE_SECTION = "#6489a7",
  SECTION_BORDER = "#465258",
  BLACK_COLOR = "#282c35",
  SKILLS_BG = "#bcd1e3",
  EDUCATION_BG = "#bcd1e3",
  NAVIGATION_BG = "#cfe3f2",

  SPACE_BETWEEN_SECTIONS = 15,

  SIZE_ITEM_TITLE = 18,
}


/**
 *  --green-1: #6bf4c8;
    --green-2: #5debac;
    --green-3: #66ed7f;
    --yellow: #ffd253;
    --orange: #ff7253;
    --red-1: #f0696a;
    --red-2: #f06a8b;
    --pink: #ff9cb4;
    --purple: #b66afe;
    --aqua: #6ac9f5;
    --blue-1: #62acfe;
    --blue-2: #686afe;
    --dark-1: #00091b;
    --dark-2: #011438;
    --white-1: #fefefe;
    --white-2: #f2f2f2;
 * 
 */