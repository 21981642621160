import Education from "../../assets/education.png";
import Skills from "../../assets/skills.png";
import Person from "../../assets/profile.png";
import Experience from "../../assets/experience.png";
import Projects from "../../assets/idea.png";
import Certifications from "../../assets/certificate.png";
import { AboutMe } from "./AboutMe";
import { ExperienceContainer } from "./Experience";
import { ProjectsContainer } from "./Projects";
import { EducationContainer } from "./Education";
import { SkillsContainer } from "./Skills";
// import { CertificationsContainer } from "./Certifications";
import { Construction } from "./Construction";

interface ISectionImages {
  [key: string]: any | undefined;
}
interface ISectionsInfo {
  [key: string]: any | undefined;
}

export const sectionsInfo: ISectionsInfo = {
  aboutMe: { src: Person, component: <AboutMe /> },
  education: { src: Education, component: <EducationContainer /> },
  experience: { src: Experience, component: <ExperienceContainer /> },
  projects: { src: Projects, component: <ProjectsContainer /> },
  skills: { src: Skills, component: <SkillsContainer /> },
  // certifications: {
  //   src: Certifications,
  //   component: <CertificationsContainer />,
  // },
};
export const sectionImages: ISectionImages = {
  aboutMe: { key: Person, toolTip: "About Me" },
  education: { key: Education, toolTip: "Education" },
  experience: { key: Experience, toolTip: "Experience" },
  projects: { key: Projects, toolTip: "Projects" },
  skills: { key: Skills, toolTip: "Skills" },
  certifications: { key: Certifications, toolTip: "Certifications" },
};
